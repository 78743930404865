<template>
  <section class="breadcrumbs-custom">
    <div class="parallax-container">
      <div class="breadcrumbs-custom-body parallax-content context-dark">
        <div class="container">
          <h2 class="">{{ title }}</h2>
        </div>
      </div>
    </div>
    <div v-if="showBreadcrumb" class="breadcrumbs-custom-footer mb-4">
      <div class="container">
        <ul class="breadcrumbs-custom-path">
          <li>Inicio</li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "base-header",
  data() {
    return {};
  },
  props: {
    title: String,
      showBreadcrumb: {
      type: Boolean,
      default: false
    },
    background: String,
  },
};
</script>