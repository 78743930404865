<template>
  <div class="">
    <base-header :title="'Editar Casa'" />
    <div class="container">
      <div class="row justify-content-md-center text-left pt-4">
        <div class="col-12">
          <form
            @submit.prevent="modifyData"
            ref="formPlant"
            v-if="loaded"
            data-form="formPlant"
          >
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-input
                  :value="formState.name"
                  label="Nombre de la Casa"
                  name="name"
                  @inputChange="inputChange"
                  :required="true"
                />
                <checkbox label="Destacado" name="relevant" :is-checked="formState.relevant"  @inputChange="inputChange" />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-input
                  :value="formState.meters"
                  label="Metros cuadrados"
                  name="meters"
                  :min="1"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
              <div class="col col-input">
                <text-input
                  :value="formState.bedrooms"
                  label="Dormitorios"
                  name="bedrooms"
                  :min="1"
                  type="number"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-input
                  :value="formState.bathrooms"
                  label="Baños"
                  name="bathrooms"
                  :min="1"
                  :step="'.01'"
                  type="number"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
              <div class="col col-input">
                <text-input
                  :value="formState.levels"
                  label="Niveles"
                  name="levels"
                  :min="1"
                  type="number"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <image-file
                  label="Imagenes para la casa"
                  name="levelImage"
                  :uploadImages="formState.media"
                  :multiple="true"
                  @inputChange="filesChange"
                  @removeImage="removeImage"
                  :levels="formState.levels"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-area
                  label="Descripción"
                  name="description"
                  :value="formState.description"
                  :rows="4"
                  @inputChange="inputChange"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-multiple-input
                  label="Notas importantes"
                  name="notes"
                  :value="formState.notes"
                  @inputChange="inputChange"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <image-file
                  label="Galeria"
                  :name="'galleryImages'"
                  :uploadImages="formState.media"
                  :multiple="true"
                  @inputChange="filesChange"
                  @removeImage="removeImage"
                />
              </div>
              <div class="col col-input">
                <text-input
                  :value="formState.video"
                  label="Video"
                  name="video"
                  type="url"
                  :validation="'url'"
                  @inputChange="inputChange"
                />
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-12">
              <div class="alert mt-2"
                v-if="alert.visibility"
                :class="alert.status" role="alert">{{alert.label}}</div>
              <hr class="py-3" />
              <button
                @click.prevent="showModal()"
                class="btn btn-warning float-right ml-3"
              >
                Borrar Casa
              </button>
              <input
                @click="modifyData"
                type="submit"
                value="Editar casa"
                :disabled="!isValidForm"
                class="btn btn-primary float-right"
              />
            </div>
          </div>
          <base-modal
            :description="'Esta seguro que desea eliminar esta casa?'"
            :title="'Eliminar la casa'"
            @emitConfirmation="emitConfirmation"
            :showModal="showConfirmationModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageFile from "./../../../components/forms/imageFile";
import checkbox from "./../../../components/forms/checkbox";
import textareaInput from "./../../../components/forms/textarea";
import textInput from "./../../../components/forms/inputForm";
import textMultipleInput from "./../../../components/forms/inputMultipleForm";

export default {
  name: "Home",
  data() {
    return {
      formState: {},
      formValid: {
        name: false,
        description: false,
        galleryImages: false,
      },
      loaded: false,
      imagesToUpload: [],
      showConfirmationModal: false,
      alert: {
        status: "alert-info",
        label: "La casa ha sido editado exitosamente",
        visibility: false,
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    isValidForm() {
      let hasValidValue = Object.values(this.formValid);

      return hasValidValue.every((currentValue) => currentValue === true);
    }
  },
  components: {
    "text-area": textareaInput,
    "text-input": textInput,
    textMultipleInput,
    imageFile,
    checkbox,
  },
  methods: {
    inputChange(data) {
      this.formState[data.name] = data.val;
      if(this.formValid.hasOwnProperty(data.name))
        this.formValid[data.name] = data.valid;
    },
    filesChange(data) {
      const index = this.imagesToUpload.findIndex(
        (elem) => elem.name === data.name
      );
      const image = this.formState.media.find(
        (elem) => elem.field === data.name && !data.multiple
      );

      if (index > -1) this.imagesToUpload.splice(index, 1);

      if (image) image["updated"] = "removed";
      if(this.formValid.hasOwnProperty(data.name))
        this.formValid[data.name] = data.valid;
      this.imagesToUpload.push(data);
    },
    emitConfirmation(value) {
      if (value) {
        this.deleteData();
      }

      this.showConfirmationModal = false;
    },
    removeImage(data) {
      if (data.filename) {
        const image = this.formState.media.find(
          (elem) => elem.filename === data.filename
        );
        image["updated"] = "removed";
      } else {
        const index = this.imagesToUpload.findIndex(
          (elem) => elem.name === data.name
        );
        this.imagesToUpload[index].val.splice(data.index, 1);
      }
    },

    async getData() {
      const accessToken = await this.$msal.acquireToken();
      this.$http
        .get(
          `${this.$apiBaseUrl}/models/${this.$route.query.id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=utf-8",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          this.formState = response.data;
          this.loaded = true;
        });
    },
    async modifyData() {
      this.alert.visibility = false;
      var formData = new FormData();
      var totalSize = 0;

      this.imagesToUpload.forEach((element) => {
        if (element.multiple) {
          for (var i = 0; i < element.val.length; i++) {
            var file = element.val[i];
            totalSize += file.size;
            formData.append(element.name, file, file.name);
          }
        } else {
          formData.append(element.name, element.val);
        }
      });

      totalSize = totalSize / 1048576; //size in mb

      if (totalSize > 4.0) {
        this.alert.visibility = true;
        this.alert.status = "alert-warning";
        this.alert.label = "El limite de subida de archivos es 4Mb.";
        return;
      }

      this.formState.media = JSON.stringify(this.formState.media);
      for (const key in this.formState) {
        formData.append(`${key}`, this.formState[key]);
      }

      const accessToken = await this.$msal.acquireToken();
      this.$http
        .post(
          `${this.$apiBaseUrl}/models/update`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          this.alert.visibility = true;
          if (response.data.success) {
            this.alert.status = "alert-info";
            this.alert.label = "La casa ha sido editado exitosamente.";
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.alert.status = "alert-warning";
            this.alert.label = "Hubo un error al editar la casa.";
          }
          
        });
    },
    showModal() {
      this.showConfirmationModal = true;
    },
    async deleteData() {
      const accessToken = await this.$msal.acquireToken();
      this.$http
        .post(
          `${this.$apiBaseUrl}/models/delete`,
          { id: this.formState.id, modelId: this.formState.modelId },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=utf-8",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          this.alert.visibility = true;
          if (response.data.success) {
            this.alert.status = "alert-info";
            this.alert.label = "La casa ha sido borrado exitosamente.";
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.alert.status = "alert-warning";
            this.alert.label = "Hubo un error al borrar la casa.";
          }
        });
    },
  },
};
</script>
<style scoped>
form .row {
  padding: 15px 0;
}
</style>