<template>
  <div class="d">
    <base-header :title="'Casas'" :show-breadcrumb="true"/>
    <div class="row">
      <div class="col-md-12">
        <router-link class="btn btn-primary" to="/producto">Nueva casa</router-link>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <ul class="list-group mb-3 text-left">
          <li
            class="list-group-item"
            v-for="product in products"
            :key="product.id"
          >
            <router-link class="" :to="{name: 'edit', query: {id: product.modelId}}">{{
              product.name
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      products: [],
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      const accessToken = await this.$msal.acquireToken();

      this.$http
        .get(`${this.$apiBaseUrl}/models`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        })
        .then((resp) => {
          this.products = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
