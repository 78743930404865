<template>
  <div class="form-group">
    <label :for="name">{{ label }}</label>
    <textarea
      @change="emitChange"
      type="text"
      ref="input"
      class="form-control editor"
      :class="classes"
      :name="name"
      :id="name"
      v-model="state"
      :rows="rows"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "text-area",
  components: {},
  data: function () {
    return {
      count: 0,
      state: "",
      isValid: false
    };
  },
  mounted() {
    this.state = this.value;
    if(this.value) {
      let hasValidValue = this.value.length > 0;
      this.isValid = !hasValidValue ? false : true;
      this.emitChange(this.value);
    }

    var description = CKEDITOR.replace("description");
    description.on("change", (evt) => {
      let hasValidValue = evt.editor.getData().length > 0;
      this.isValid = !hasValidValue ? false : true;
      this.emitChange(evt.editor.getData());
    });
  },
  methods: {
    emitChange(data) {
      this.$emit("inputChange", {
        val: data,
        name: this.name,
        valid: this.isValid
      });
    },
  },
  props: {
    label: String,
    name: String,
    value: String,
    rows: Number,
    classes: String,
  },
};
</script>
<style scoped>
.form-group label {
  margin-bottom: 5px;
}
</style>