import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('user-token'),
    status: '',
  },

  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
  },

  mutations: {
    auth_request: (state) => {
      state.status = 'loading'
    },
    auth_success: (state, token) => {
      localStorage.setItem('user-token', token);
      state.status = 'success'
      state.token = token
    },
    auth_error: (state) => {
      state.status = 'error'
    },
    logout(state){
      localStorage.removeItem('user-token');
      state.status = ''
      state.token = ''
    },
  },

  actions: {
    auth_request: ({commit}, user) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('auth_request')
        axios({url: '/login', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            localStorage.setItem('user-token', token) // store the token in localstorage
            commit('auth_success', token)
            // you have your token, now log in your user :)
            resolve(resp)
          })
        .catch(err => {
          commit('auth_error', err)
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('user-token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  }
});