import { LogLevel, PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

const MsalPlugin = {
  // It takes the global Vue object as well as user-defined options.
  install(Vue, options) {
    const msalConfig = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        knownAuthorities: [options.knownAuthority]
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          logLevel: LogLevel.Verbose
        }
      }
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    Vue.prototype.$msal = {
      pluginOptions: options,
      isAuthenticated: () => {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
      },

      signIn: async (afterSuccessSignIn) => {
        try {
          const loginRequest = {
            scopes: ["openid", "profile", "offline_access", "30c3518c-1b6c-4f4c-bfad-b30d5845e140"],
          };
          const loginResponse = await msalInstance.loginPopup(loginRequest);
          this.isAuthenticated = !!loginResponse.account;

          if (afterSuccessSignIn) {
            afterSuccessSignIn(loginResponse);
          }
        } catch (err) {
          // handle error
          console.log("Login error");
          console.log(err);
          if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
            try {
              const passwordResetResponse = await msalInstance.loginPopup({
                scopes: ["openid", "profile", "offline_access"],
                authority: this.pluginOptions.passwordAuthority,
              });
              this.isAuthenticated = !!passwordResetResponse.account;
            } catch (passwordResetError) {
              console.error(passwordResetError);
            }
          } else {
            this.isAuthenticated = false;
          }
        }

      },

      signOut: async () => {
        await msalInstance.logout();
        this.isAuthenticated = false;
      },

      acquireToken: async () => {
        const request = {
          account: msalInstance.getAllAccounts()[0],
          scopes: ["https://incopoascr.onmicrosoft.com/api/casas.write", "https://incopoascr.onmicrosoft.com/api/casas.read"]
        };
        try {
          const response = await msalInstance.acquireTokenSilent(request);
          return response.accessToken;
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenPopup(request).catch((popupError) => {
              console.error(popupError);
            });
          }
          return false;
        }
      },

      getIsAuthenticated: () => {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
      }
    }
  }
};

export default MsalPlugin;