<template>
  <div id="app">
    <header class="section page-header">
      <!-- RD Navbar-->
      <div class="rd-navbar-wrap" style="height: 130px">
        <nav
          class="rd-navbar rd-navbar-classic rd-navbar-original rd-navbar-static"
          data-layout="rd-navbar-fixed"
          data-sm-layout="rd-navbar-fixed"
          data-md-layout="rd-navbar-fixed"
          data-md-device-layout="rd-navbar-fixed"
          data-lg-layout="rd-navbar-static"
          data-lg-device-layout="rd-navbar-fixed"
          data-xl-layout="rd-navbar-static"
          data-xl-device-layout="rd-navbar-static"
          data-xxl-layout="rd-navbar-static"
          data-xxl-device-layout="rd-navbar-static"
          data-lg-stick-up-offset="100px"
          data-xl-stick-up-offset="100px"
          data-xxl-stick-up-offset="100px"
          data-lg-stick-up="true"
          data-xl-stick-up="true"
          data-xxl-stick-up="true"
        >
          <div class="rd-navbar-main-outer">
            <div class="rd-navbar-main">
              <!-- RD Navbar Panel-->
              <div class="rd-navbar-panel">
                <!-- RD Navbar Toggle-->
                <button
                  class="rd-navbar-toggle toggle-original"
                  data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                >
                  <span></span>
                </button>
                <!-- RD Navbar Brand-->
                <div class="rd-navbar-brand">
                  <!--Brand--><a class="brand" href="/"
                    ><img
                      class="brand-logo-dark"
                      :src="require('./assets/logo.png')"
                      alt=""
                  /></a>
                </div>
              </div>
              <div class="rd-navbar-nav-wrap toggle-original-elements">
                <ul class="rd-navbar-nav d-flex" v-if="isAuthenticated">
                  <li class="rd-nav-item">
                    <a href="/">Casas</a>
                  </li>
                  <li class="rd-nav-item">
                    <div class="rd-navbar-fixed-element-2 select-inline">
                      <button
                        @click.prevent="logout()"
                        class="btn btn-primary btn-sm float-right mr-3"
                      >
                        Cerrar sesion
                      </button>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="rd-navbar-project">
                <div class="rd-navbar-project-header">
                  <button
                    class="rd-navbar-project-hamburger rd-navbar-project-hamburger-close"
                    type="button"
                    data-multitoggle=".rd-navbar-main"
                    data-multitoggle-blur=".rd-navbar-wrap"
                    data-multitoggle-isolate=""
                  >
                    <span class="project-close"
                      ><span></span><span></span
                    ></span>
                  </button>
                  <h5 class="rd-navbar-project-title">Our Contacts</h5>
                </div>
                <div class="rd-navbar-project-content">
                  <div>
                    <div>
                      <!-- Owl Carousel-->
                      <div
                        class="owl-carousel owl-loaded owl-drag"
                        data-items="1"
                        data-dots="true"
                        data-autoplay="true"
                        style=""
                      >
                        <div class="owl-stage-outer">
                          <div
                            class="owl-stage"
                            style="
                              transform: translate3d(-1038px, 0px, 0px);
                              transition: all 0.25s ease 0s;
                              width: 2422px;
                            "
                          >
                            <div class="owl-item cloned" style="width: 346px">
                              <img
                                src="images/about-6-350x269.jpg"
                                alt=""
                                width="350"
                                height="269"
                              />
                            </div>
                            <div class="owl-item cloned" style="width: 346px">
                              <img
                                src="images/about-7-350x269.jpg"
                                alt=""
                                width="350"
                                height="269"
                              />
                            </div>
                            <div class="owl-item" style="width: 346px">
                              <img
                                src="images/about-5-350x269.jpg"
                                alt=""
                                width="350"
                                height="269"
                              />
                            </div>
                            <div class="owl-item active" style="width: 346px">
                              <img
                                src="images/about-6-350x269.jpg"
                                alt=""
                                width="350"
                                height="269"
                              />
                            </div>
                            <div class="owl-item" style="width: 346px">
                              <img
                                src="images/about-7-350x269.jpg"
                                alt=""
                                width="350"
                                height="269"
                              />
                            </div>
                            <div class="owl-item cloned" style="width: 346px">
                              <img
                                src="images/about-5-350x269.jpg"
                                alt=""
                                width="350"
                                height="269"
                              />
                            </div>
                            <div class="owl-item cloned" style="width: 346px">
                              <img
                                src="images/about-6-350x269.jpg"
                                alt=""
                                width="350"
                                height="269"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="owl-nav disabled">
                          <div class="owl-prev"></div>
                          <div class="owl-next"></div>
                        </div>
                        <div class="owl-dots">
                          <div class="owl-dot"><span></span></div>
                          <div class="owl-dot active"><span></span></div>
                          <div class="owl-dot"><span></span></div>
                        </div>
                      </div>
                      <ul class="contacts-modern">
                        <li>
                          <a href="#"
                            ></a
                          >
                        </li>
                        <li><a href="tel:+50627643518">+506 2764 3518</a></li>
                      </ul>
                    </div>
                    <div>
                      <ul class="list-inline list-social list-inline-xl">
                        <li><a class="icon mdi mdi-facebook" href="#"></a></li>
                        <li><a class="icon mdi mdi-twitter" href="#"></a></li>
                        <li><a class="icon mdi mdi-instagram" href="#"></a></li>
                        <li>
                          <a class="icon mdi mdi-google-plus" href="#"></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>

    <router-view />
    <footer>
      <div class="footer-classic-panel">
        <div class="container">
          <p class="rights text-center">
            <span>&copy;&nbsp;</span><span class="copyright-year"></span
            ><span>&nbsp;</span><span>Homes by Incopoas. </span
            ><span data-i18n="all-rights">&nbsp; Derechos Reservados.</span>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  methods: {
    logout: async function () {
      this.$store.commit("logout");
      await this.$msal.signOut();
    },
  },
  computed:{
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    }
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.parallax-container {
  background-color: #2F53A1;
}
</style>
