<template>
  <div class="custom-form-input">
    <label :for="name">{{ label }}</label>
    <input
      @change="emitChange"
      :type="type"
      ref="input"
      class="form-control"
      :class="classes"
      :name="name"
      :min="min"
      :step="step"
      v-model="state"
      :required="required"
    />
  </div>
</template>

<script>
export default {
  name: "inputText",
  components: {},
  data: function () {
    return {
      count: 0,
      state: "",
    };
  },
  created() {
    this.state = this.value;
    if(this.value) {
      this.emitChange();
    }
  },
  computed: {
    isValid() {
      let hasValidValue = false;
      switch (this.type) {
        case 'text':
          hasValidValue = !!this.state;
          break;
        case 'number':
          hasValidValue = this.state > 0;
          break;
        case 'url':
          hasValidValue = this.validateYouTubeUrl(this.state);
          break;
        default:
          hasValidValue = !!this.state;
          break;
      }

      return this.required && !hasValidValue ? false : true;
    }
  },
  methods: {
    emitChange() {
      var validNumber = this.min && this.state > this.min;
      if(this.type === 'number' && !validNumber) {
        this.state = this.min;
      }

      this.$emit("inputChange", { val: this.state, name: this.name, valid: this.isValid });
      
    },
    validateYouTubeUrl() {
      if (this.state != undefined || this.state != "") {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = this.state.match(regExp);
        if (match && match[2].length !== 11) {
          console.log("asasasasas"); 
        }
      }
    },
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: String,
    name: String,
    min: Number,
    value: [String, Number],
    validation: String,
    classes: String,
    step:{
      type: String,
      default: "1",
    },
    required: Boolean,
  },
};
</script>
<style scoped>
.custom-form-input {
  margin-bottom: 15px;
}

.custom-form-input label {
  margin-bottom: 5px;
}
.custom-form-input input {
  height: 43px;
}
</style>
