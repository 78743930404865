<template>
  <div class="form-group">
    <div class="custom-control custom-checkbox">
      <input 
        @change="emitChange"
        type="checkbox" 
        v-model="state"
        value="checked"
        :class="classes"
        :name="name"
        ref="input"
        class="custom-control-input" 
        id="customCheck1">
      <label class="custom-control-label" for="customCheck1">{{ label }}</label>
    </div>
    <input>   
   </div>
</template>

<script>
export default {
  name: "checkbox",
  components: {},
  data: function () {
    return {
      count: 0,
      state: [],
    }
  },
  created() {
    this.isChecked && this.state.push('checked');
  },
  methods: {
    emitChange() {
      this.$emit("inputChange", {'val': this.state.length > 0, 'name': this.name});
    },
  },
  props: {
    label: String,
    name: String,
    isChecked: Boolean,
    rows: Number,
    classes: String
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 0;
}
</style>