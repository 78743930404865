
<template>
  <div class="container-fluid" >
    <section class="breadcrumbs-custom">
      <div class="parallax-container">
        <div class="breadcrumbs-custom-body parallax-content context-dark">
          <div class="container">
            <h2 class="">Homes by Incopoas</h2>
          </div>
        </div>
      </div>
      <div class="breadcrumbs-custom-footer">
        <div class="container">
          <ul class="breadcrumbs-custom-path">
            <li>Inicie sesión para comenzar</li>
          </ul>
        </div>
      </div>
    </section>
    <div class="mx-auto d-block px-3 my-5" max-width="600">
      <button class="btn btn-primary mr-4" @click="login">
        Acceder
      </button>
    </div>
  </div>
</template> 

<script>
export default {
  name: "Home",
  components: {},
  methods: {
    login: async function () {
      await this.$msal.signIn((authenticationResult) => {
        this.$store.commit('auth_success', authenticationResult)
        this.$router.push('/');
      });
      //this.mgr.signinRedirect();
    },
  },
  mounted() {
    //this.mgr = new window.Oidc.UserManager(this.config);
  },
};
</script>
