
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import MsalPlugin from './plugins/msal-plugin';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$apiBaseUrl= process.env.VUE_APP_API_BASE_URL;


const requireComponent = require.context(
  // La ruta relativa de la carpeta de componentes
  './components/commons',
  // Si mirar o no en las subcarpetas
  false,
  // La expresión regular utilizada para hacer coincidir los nombres de los componentes básicos de los ficheros
  /base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Obtener la configuración de los componentes
  const componentConfig = requireComponent(fileName)

  // Obtener el nombre PascalCase del componente
  const componentName = upperFirst(
    camelCase(
      // Quitar el `./`en el comienzo y la extensión del nombre del archivo 
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  // Registrar el componente a nivel global
  Vue.component(
    componentName,
    // Busca las opciones de componentes en `.default`, que
    // existen si el componente fue exportado con `export default`,
    // de lo contrario volver a la raíz del módulo.
    componentConfig.default || componentConfig
  )
})


const msalOptions = {
  clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
  loginAuthority:  process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
  passwordAuthority: process.env.VUE_APP_MSAL_PASSWORD_RESET_AUTHORITY,
  knownAuthority: process.env.VUE_APP_MSAL_KNOWN_AUTHORITY,
};

Vue.use(MsalPlugin, msalOptions);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App) 
}).$mount('#app')
