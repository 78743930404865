<template>
  <div class="d">
    <base-header :title="'Nueva Casa'" :show-breadcrumb="true" />
    <div class="container-lg">
      <div class="row">
        <div class="col-12">
          <form @submit.prevent ref="formPlant" id="model-form">
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-input
                  :value="formState.name"
                  label="Nombre de la Casa"
                  name="name"
                  @inputChange="inputChange"
                  :required="true"
                />
                <checkbox
                  label="Destacado"
                  name="relevant"
                  @inputChange="inputChange"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-input
                  :value="formState.meters"
                  label="Metros cuadrados"
                  name="meters"
                  type="number"
                  :min="1"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
              <div class="col col-input">
                <text-input
                  :value="formState.bedrooms"
                  label="Dormitorios"
                  name="bedrooms"
                  type="number"
                  :min="1"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-input
                  :value="formState.bathrooms"
                  label="Baños"
                  name="bathrooms"
                  type="number"
                  :min="1"
                  :step="'.01'"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
              <div class="col col-input">
                <text-input
                  :value="formState.levels"
                  label="Niveles"
                  name="levels"
                  type="number"
                  :min="1"
                  @inputChange="inputChange"
                  :required="true"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <image-file
                  label="Imagenes para la casa"
                  :uploadImages="formState.media"
                  name="levelImage"
                  :multiple="true"
                  @inputChange="filesChange"
                  @removeImage="removeImage"
                  :levels="formState.levels"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-area
                  classes="editor"
                  label="Descripción"
                  name="description"
                  :rows="4"
                  @inputChange="inputChange"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <text-multiple-input
                  label="Notas importantes"
                  name="notes"
                  @inputChange="inputChange"
                />
              </div>
            </div>
            <div class="d-flex justify-content-md-center text-left">
              <div class="col col-input">
                <image-file
                  label="Galeria"
                  name="galleryImages"
                  :uploadImages="formState.media"
                  :multiple="true"
                  @inputChange="filesChange"
                  @removeImage="removeImage"
                />
              </div>
              <div class="col col-input">
                <text-input
                  :value="formState.video"
                  label="Video"
                  name="video"
                  type="url"
                  :validation="'url'"
                  @inputChange="inputChange"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            class="alert mt-2"
            v-if="alert.visibility"
            :class="alert.status"
            role="alert"
          >
            {{ alert.label }}
          </div>
          <hr class="py-3" />
          <router-link class="btn btn-primary float-left" :to="{ path: '/' }"
            >Volver</router-link
          >

          <input
            type="submit"
            form="model-form"
            :disabled="!isValidForm"
            @click="insertData"
            value="Crear casa"
            class="btn btn-primary float-right"
          />
        </div>
      </div>
    </div>
    <div class="loading">
    </div>
  </div>
</template>

<script>
import imageFile from "./../../../components/forms/imageFile";
import checkbox from "./../../../components/forms/checkbox";
import textareaInput from "./../../../components/forms/textarea";
import textInput from "./../../../components/forms/inputForm";
import textMultipleInput from "./../../../components/forms/inputMultipleForm";

export default {
  name: "Home",
  data() {
    return {
      imagesToUpload: [],
      formValid: {
        name: false,
        description: false,
        galleryImages: false,
      },
      formState: {
        name: "",
        meters: 1,
        bedrooms: 1,
        bathrooms: 1,
        levels: 1,
        description: "",
        media: [],
        notes: [],
      },
      alert: {
        status: "alert-info",
        label: "La casa ha sigo guardado exitosamente",
        visibility: false,
      },
    };
  },
  created() {},
  components: {
    "text-area": textareaInput,
    "text-input": textInput,
    textMultipleInput,
    imageFile,
    checkbox,
  },
  computed: {
    isValidForm() {
      let hasValidValue = Object.values(this.formValid);

      return hasValidValue.every((currentValue) => currentValue === true);
    },
  },
  methods: {
    inputChange(data) {
      this.formState[data.name] = data.val;
      if (this.formValid.hasOwnProperty(data.name))
        this.formValid[data.name] = data.valid;
    },
    filesChange(data) {
      const index = this.imagesToUpload.findIndex(
        (elem) => elem.name === data.name
      );

      if (index > -1) this.imagesToUpload.splice(index, 1);
      if (this.formValid.hasOwnProperty(data.name))
        this.formValid[data.name] = data.valid;
      this.imagesToUpload.push(data);
    },
    removeImage(data) {
      const index = this.imagesToUpload.findIndex(
        (elem) => elem.name === data.name
      );
      this.imagesToUpload[index].val.splice(data.index, 1);
    },
    async insertData() {
      this.alert.visibility = false;
      var formData = new FormData();
      var totalSize = 0;

      this.imagesToUpload.forEach((element) => {
        if (element.multiple) {
          for (var i = 0; i < element.val.length; i++) {
            var file = element.val[i];
            totalSize += file.size;
            formData.append(element.name, file, file.name);
          }
        } else {
          formData.append(element.name, element.val);
        }
      });

      totalSize = totalSize / 1048576; //size in mb

      if (totalSize > 4.0) {
        this.alert.visibility = true;
        this.alert.status = "alert-warning";
        this.alert.label = "El limite de subida de archivos es 4Mb.";

        return;
      }

      for (const key in this.formState) {
        formData.append(`${key}`, this.formState[key]);
      }

      const accessToken = await this.$msal.acquireToken();

      this.$http
        .post(
          `${this.$apiBaseUrl}/models/insert`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          this.alert.visibility = true;
          if (response.data.success) {
            this.alert.status = "alert-info";
            this.alert.label = "La casa ha sigo guardado exitosamente.";

            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.alert.status = "alert-warning";
            this.alert.label = "Hubo un error al guardar la casa.";
          }
        });
    },
  },
};
</script>
<style scoped>
form .row {
  padding: 15px 0;
}

form .col-input {
  padding: 0 10px;
}
</style>